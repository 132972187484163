import React, {useEffect} from 'react'
import {navigate} from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'

const NotFoundPage = () => {
	useEffect(() => {
		setInterval(() => {
			navigate('/')
		}, 5000)
	}, [])

	return (
		<Layout>
			<Seo title='404: Not found' />
			<main className='notFound-page'>
				<div className='notFound-message'>
					<h1>404</h1>
					<p>
						هذه الصفحة غير موجودة. سوف ينتقل إلى الصفحة الرئيسية بعد ٥ ثوان.
					</p>
				</div>
			</main>
		</Layout>
	)
}

export default NotFoundPage
